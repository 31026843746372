<template>
  <footer class="footer" id="footer">
    <div class="footer-container container">
      <div class="footer-text">
        © FiveStones, a Numiracle Group company,<br class="footer-br" />
        all rights reserved.
      </div>
      <div class="footer-link">
        <a
          class="btn-link"
          href="https://fivestones.net/en/bonder-privacy-policy/"
          target="_blank"
          >Privacy Policy</a
        >
        <a
          class="btn-link"
          href="https://fivestones.net/en/bonder-terms-conditions/"
          target="_blank"
          >Terms &amp; Conditions</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
