<template>
  <div class="pages" ref="indexPage" @mousemove="indexImgMoveHandler">
    <Header mode="normal" class="index-header">
      <template v-slot:avater><IndexHeaderMenu /></template>
    </Header>
    <div class="wrapper">
      <MainProgress />
      <main class="main index">
        <div class="index-container page-container container">
          <div class="index-content">
            <div class="index-left">
              <h1 class="index-title">One Tool, Many Benefits</h1>
              <div class="index-subtitle">
                An automation tool bridging SA360 & facebook
              </div>
              <p class="index-subscription">
                The FiveStones Bonder, an automation tool which leverages a
                SA360 & FB API, helps advertisers automatically upload their own
                or their clients Facebook performance metrics to SA360.
              </p>
              <div class="index-btn">
                <a
                  href="https://fivestones.net/en/contact-us/"
                  target="_blank"
                  class="btn btn-primary"
                  >Request a demo</a
                >
              </div>
            </div>
            <div class="index-right">
              <div class="index-img" ref="indexImg">
                <div
                  class="index-img-item index-img-box-shadow"
                  :style="imgShadowTranslate"
                >
                  <img
                    :src="`${publicPath}img/index-img-box-shadow.png`"
                    alt=""
                  />
                </div>
                <div class="index-img-item index-img-box">
                  <img :src="`${publicPath}img/index-img-box.svg`" alt="" />
                </div>
                <div
                  class="index-img-item index-img-logo-shadow"
                  :style="iconShadowTranslate"
                >
                  <img
                    :src="`${publicPath}img/index-img-logo-shadow.png`"
                    alt=""
                  />
                </div>
                <div class="index-img-item index-img-logo">
                  <img :src="`${publicPath}img/index-img-logo.svg`" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="index-bottomBg">
            <img :src="`${publicPath}img/login-bg.jpg`" alt="" />
          </div>
        </div>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../containers/Header.vue'
import Footer from '../containers/Footer.vue'
import MainProgress from '../components/MainProgress.vue'
import IndexHeaderMenu from '../components/IndexHeaderMenu.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Index',
  components: {
    Header,
    Footer,
    MainProgress,
    IndexHeaderMenu
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      shadowTranslateDuration: {
        x: 8,
        y: 4
      },
      shadowTranslateX: 0,
      shadowTranslateY: 0
    }
  },
  async mounted() {
    await this['homepageMounted']()
  },
  computed: {
    ...mapState({
      userSelf: state => state.user.self
    }),
    imgShadowTranslate() {
      return {
        transform: `translate(${this.shadowTranslateX}px,${this.shadowTranslateY}px)`
      }
    },
    iconShadowTranslate() {
      return {
        transform: `translate(${this.shadowTranslateX / 2}px,${this
          .shadowTranslateY / 2}px)`
      }
    }
  },
  methods: {
    ...mapActions(['homepageMounted', 'setLoadingStatus']),
    indexImgMoveHandler(e) {
      const { clientX, clientY } = e
      let wrapperWidth = this.$refs.indexPage.clientWidth
      let wrapperHeight = this.$refs.indexPage.clientHeight
      let centerX = wrapperWidth / 2
      let centerY = wrapperHeight / 2
      let x = (clientX / centerX - 1).toFixed(2)
      let y = (clientY / centerY - 1).toFixed(2)

      this.shadowTranslateX = x * this.shadowTranslateDuration.x
      this.shadowTranslateY = y * this.shadowTranslateDuration.y
    },
    indexImgLeafeHandler() {
      this.shadowTranslateX = 0
      this.shadowTranslateY = 0
    }
  }
}
</script>
