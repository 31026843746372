<template>
  <div class="index-menu">
    <div class="index-menu-item index-menu-home">
      <router-link to="/" class="btn ">HOME</router-link>
    </div>
    <div class="index-menu-item index-menu-contact">
      <a
        href="https://fivestones.net/en/contact-us/"
        target="_blank"
        class="btn "
        >CONTACT US</a
      >
    </div>
    <div class="index-menu-item index-menu-login">
      <router-link class="btn btn-primary" to="/pages/login">LOGIN</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexHeaderMenu'
}
</script>
