<template>
  <b-progress :value="progress" v-show="progressStatus"></b-progress>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'MainProgress',
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['progressStatus', 'progress'])
  },
  methods: {}
}
</script>
