<template>
  <header class="header">
    <div class="header-container">
      <div class="header-row">
        <slot name="headerToggler"></slot>
        <div class="header-content">
          <div class="header-logo">
            <a href="https://fivestones.net" target="_blank">
              <img :src="`${publicPath}img/fs-logo.png`" alt=""
            /></a>
          </div>
          <div class="header-divider"></div>
          <div class="header-bonder text-primary">
            <router-link to="/">
              <img :src="`${publicPath}img/bonder-logo.svg`" alt=""
            /></router-link>
          </div>
        </div>

        <slot name="avater"></slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: { mode: { type: String, required: true } },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  }
}
</script>
